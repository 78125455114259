import { useState, useRef, useEffect } from "react";
import Create from "@/components/Create";
import Publish from "@/components/Publish";
import { IAgentInfo } from "@/types/common";
import Styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getImageUrl } from "@/utils/common";
import Preview from "./components/Preview";
import { toGetAgentInfo } from "@/apis/common";
import { useTranslation } from "react-i18next";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import arrowLeftPic from "@/assets/common/arrow-left.svg";
import arrowLeftLight from "@/assets/common/arrow-left-light.svg";
import { Popover, Tooltip, message } from "antd";
import _ from "lodash";
import useThemeHooks from "@/hooks/useThemeHooks";

import agentBg from "@/assets/workflow/default-portrait.svg";
import agentB_proBg from "@/assets/workflow/b-prod-portrait.svg";
import PublishIcon from "@/assets/workflow/publish-normal.svg";
import PublishDisabledIcon from "@/assets/workflow/publish-disabled.svg";

import ConfirmModal from "@/components/Modal";

import RollbackIcon from "./rollback";
import Save from "./save";
import useCompanyInfoHooks from "@/hooks/useCompanyHooks";
import { updateFiles } from "@/store/reducer/knowledgeFiles";
import store from "@/store";

const envMode: string = process.env.REACT_APP_ENV || "prod";

const AgentManage = () => {
  const { t } = useTranslation();

  const theme = useThemeHooks();
  const createId = useRef<number | undefined>();
  const ConfirmModalRef = useRef<any>(null);

  const [params] = useSearchParams();

  const { company_id } = useCompanyInfoHooks();

  const navigate = useNavigate();

  let stepUrl = params.getAll("step")[0];
  let mode = params.getAll("mode")[0];
  const show_mode = mode === "card" ? 2 : 1;
  const [step, setStep] = useState(stepUrl || "create");

  const [lastUpdateTime, setLastUpdateTime] = useState("");

  const [pageLoading, setPageLoading] = useState(false);

  const [saveStatus, setSaveStatus] = useState("Saved");

  const [hasInit, setHasInit] = useState(false);

  const [isSaveHover, setIsSaveHover] = useState(false);
  const [isRollBackHover, setIsRollBackHover] = useState(false);
  const [isPublish, setIsPublish] = useState(false);

  const PublicRef = useRef<any>();
  const CreateRef = useRef<any>();
  const createInfoRef = useRef<any>();
  const [createInfo, setCreateInfo] = useState<IAgentInfo>({
    name: "", // 名称
    intro: "", // 介绍
    agent_type: "", // agent类型
    greeting: "", //问候语
    ai_framework: 101, //框架版本
    show_mode,
    edit_mode: 1,
    flow_config: {
      sender: {
        // 发送方
        human_input_mode: "", //人工输入模式
        max_consecutive_auto_reply: 5, //最大回复次数
        system_message: "", //系统输入提示词
      },

      receiver: [
        {
          human_input_mode: "", //人工输入模式
          max_consecutive_auto_reply: 5, //最大回复次数
          system_message: "", //系统输入提示词
          model: "gpt-4-1106-preview", //模型供应商
          temperature: 0.2, //情感温度
          name: "",
          avatar_url: "",
        },
      ],
      card_images: [],
      card_video: "",
      skills: [], //能力列表
      knowledge_folder: [],
      card_documents: [],
    },
  });

  const onPublishClick = () => {
    CreateRef.current?.validateAndUpdate((isSuccess?: boolean) => {
      if (isSuccess) {
        isUnsavedContent.current = false;
        PublicRef.current?.publishAction();
        return;
      }
      message.success(t("create.saveFail"));
    }, false);
  };

  useEffect(() => {
    setIsPublish(!!createInfo.pub_agent_id);
  }, [createInfo]);

  useEffect(() => {
    let step = params.getAll("step")[0];
    if (step) {
      setStep(step);
    }
  }, []);

  // 获取angent 信息
  useEffect(() => {
    let Id = Number(params.getAll("id")[0]);
    if (Id) {
      createId.current = Id;
      getAgentInfo(Id);
    }
  }, []);

  const back = () => {
    if (isUnsavedContent.current) {
      navigate(1);
      ConfirmModalRef?.current?.showModal();
      store.dispatch(updateFiles({ knowledgeFiles: [], from: "mode" }));
      return;
    }
    navigate("/my_agent");
  };

  // 获取Agent信息
  const getAgentInfo = (Id: number) => {
    const type = params.getAll("type")[0];
    if (type === "private") {
      setPageLoading(true);
      toGetAgentInfo({
        id: Id,
      }).then((res: any) => {
        setPageLoading(false);
        if (res?.data?.status === 200) {
          setCreateInfo(res.data.data);
          setLastUpdateTime(res.data.data.update_time);
        }
      });
    } else if (type === "public") {
    }
  };

  // 检查Agent必填字段
  const checkAgentInfo = () => {};

  // 过滤数组的空值
  const filterEmptyValues = (key: string, arr: any[]) => {
    if (key === "flow_config.card_images") {
      return arr.filter(
        (item) => item !== null && item !== undefined && item !== ""
      );
    }
    return arr;
  };

  const isUnsavedContent = useRef(false);

  useEffect(() => {
    const beforeunloadHandler = (event: BeforeUnloadEvent) => {
      // 检查页面是否有内容需要保存
      if (isUnsavedContent.current) {
        // 显示警告消息
        event.returnValue = t("create.unsavedTitle");
      }
    };

    const handlePopState = (event: any) => {
      event.preventDefault();
      navigate(1);
      // 在这里执行需要的操作，比如检查是否有内容未保存，并提示用户
      if (isUnsavedContent.current) {
        ConfirmModalRef?.current?.showModal();
      } else {
        navigate("/my_agent");
      }
    };

    window.addEventListener("beforeunload", beforeunloadHandler);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", beforeunloadHandler);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isUnsavedContent.current]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
  }, []);

  // 修改创建数据
  const changeCreateInfo = (key: any, value: any) => {
    if (typeof key === "object" && key.length) {
      let newCreateInfo: any = _.cloneDeep(createInfo);
      key.forEach((item: string, index: number) => {
        _.set(newCreateInfo, item, filterEmptyValues(item, value[index]));
      });
      setCreateInfo(newCreateInfo);
      isUnsavedContent.current = true;
    } else {
      let newCreateInfo: any = _.cloneDeep(createInfo);
      _.set(newCreateInfo, key, filterEmptyValues(key, value));
      setCreateInfo(newCreateInfo);
      isUnsavedContent.current = true;
    }
  };

  const onUploadSuccess = (url: string) => {
    changeCreateInfo("avatar_url", url);
  };

  const onSaveClick = (hideTip?: boolean) => {
    CreateRef.current?.validateAndUpdate((isSuccess?: boolean) => {
      if (isSuccess) {
        isUnsavedContent.current = false;
        !hideTip && message.success(t("create.saveSuccess"));
        return;
      }
      !hideTip && message.success(t("create.saveFail"));
    }, !isUnsavedContent.current);
  };

  const getStatusIcon = () => {
    if (saveStatus === "Saved") {
      return (
        <Popover placement="top" title={""} content={"自动保存成功"}>
          <CheckCircleOutlined style={{ color: "#52c41a" }} />
        </Popover>
      );
    } else if (saveStatus === "Loading") {
      return (
        <Popover placement="top" title={""} content={"自动保存中"}>
          <LoadingOutlined />
        </Popover>
      );
    } else if (saveStatus === "UnSaved") {
      return (
        <Popover
          placement="top"
          title={""}
          content={"自动保存失败, 请检查必填字段"}
        >
          <CloseCircleOutlined style={{ color: "#eb2f96" }} />
        </Popover>
      );
    }
  };

  // agent默认值
  const defaultObj: IAgentInfo = {
    name: "", // 名称
    intro: "", // 介绍
    agent_type: "chat", // agent类型
    greeting: "greeting", //问候语
    ai_framework: 101, //框架版本
    show_mode,
    edit_mode: 1,
    flow_config: {
      sender: {
        // 发送方
        human_input_mode: "NEVER", //人工输入模式
        max_consecutive_auto_reply: 5, //最大回复次数
        system_message: "", //系统输入提示词
      },

      receiver: [
        {
          human_input_mode: "NEVER", //人工输入模式
          max_consecutive_auto_reply: 5, //最大回复次数
          system_message: "", //系统输入提示词
          model: "gpt-4-1106-preview", //模型供应商
          temperature: 0.2, //情感温度
          name: "助理智能体1",
          avatar_url: "",
          id: 1,
        },
      ],
      knowledge_base: [],
      skills: [], //能力列表
      card_images: [],
      card_video: "",
      knowledge_folder: [],
      card_documents: [],
    },
  };

  // 创建同款从localstorage拿取信息
  useEffect(() => {
    setTimeout(() => {
      const createAgentInfo = localStorage.getItem("createAgentInfo");
      if (createAgentInfo) {
        setCreateInfo(JSON.parse(createAgentInfo));
        localStorage.setItem("createAgentInfo", "");
      } else {
        let Id = Number(params.getAll("id")[0]);
        if (!Id) {
          setCreateInfo(defaultObj);
        }
      }
      setHasInit(true);
    }, 100);
  }, []);

  const isB_prod = envMode === "b_prod" || envMode === "b_test";

  return (
    <div>
      <div className={Styles.header}>
        <div className={Styles.headerLeft}>
          <span
            className={Styles.back_box}
            onClick={() => {
              back();
            }}
          >
            <img src={theme === "dark" ? arrowLeftPic : arrowLeftLight}></img>
          </span>
          <div className={Styles.info}>
            <div
              className={
                mode === "card" || createInfo.show_mode === 2
                  ? Styles.avatar_car_box
                  : Styles.avatar_box
              }
            >
              {createInfo && (
                <img
                  src={
                    getImageUrl(
                      mode === "card" || createInfo.show_mode === 2
                        ? createInfo?.flow_config?.card_images?.[0]
                        : createInfo?.avatar_url
                    ) || (isB_prod ? agentB_proBg : agentBg)
                  }
                  className={Styles.avatar_url}
                  loading="lazy"
                ></img>
              )}
            </div>
            <div className={Styles.agentName}>{createInfo.name}</div>
          </div>
        </div>
        <div className={Styles.headerRight}>
          <div className={Styles.editTip}>{t("create.saveTip")}</div>
          <div
            className={Styles.saveBtn}
            onClick={() => onSaveClick()}
            onMouseEnter={() => {
              setIsSaveHover(true);
            }}
            onMouseLeave={() => {
              setIsSaveHover(false);
            }}
          >
            <Save isSaveHover={isSaveHover} />
            {t("create.save")}
          </div>
          {isPublish && (
            <div
              className={Styles.saveBtn}
              onMouseEnter={() => {
                setIsRollBackHover(true);
              }}
              onMouseLeave={() => {
                setIsRollBackHover(false);
              }}
              onClick={() => {
                PublicRef.current?.rollBack();
              }}
            >
              <RollbackIcon isRollBackHover={isRollBackHover} />
              {t("publish.rollback")}
            </div>
          )}

          {/* 如果是-1的话就是代表当前是个人版 */}
          {company_id === -1 ? (
            <Tooltip title={t("publish.disabledToPublish")}>
              <div
                style={{
                  background: `#AAD7DF`,
                  color: "#fff",
                  cursor: "not-allowed",
                }}
                className={Styles.publishBtn}
              >
                <img src={PublishDisabledIcon} />
                {t("publish.toCompany")}
              </div>
            </Tooltip>
          ) : (
            <div
              style={
                isB_prod
                  ? {
                      background: `#007C96`,
                      color: "#fff",
                    }
                  : {}
              }
              className={Styles.publishBtn}
              onClick={() => {
                onPublishClick();
              }}
            >
              <img src={isB_prod ? PublishDisabledIcon : PublishIcon} />
              {isB_prod ? t("publish.toCompany") : t("publish.btnText")}
            </div>
          )}
        </div>
      </div>
      <div className={Styles.page}>
        <div className={Styles.right}>
          <div
            className={`${Styles.create_context} scroll-hide`}
            ref={createInfoRef}
          >
            <Create
              ref={CreateRef}
              createInfo={createInfo}
              setCreateInfo={setCreateInfo}
              createId={createId}
              changeCreateInfo={changeCreateInfo}
              setLastUpdateTime={setLastUpdateTime}
              setSaveStatus={setSaveStatus}
              saveStatus={saveStatus}
              hasInit={hasInit}
              createInfoRef={createInfoRef}
            />
            <Publish
              createId={createId}
              createInfo={createInfo}
              ref={PublicRef}
              createInfoRef={createInfoRef}
            />
          </div>
          <div className={Styles.create_preview}>
            <Preview
              createInfo={createInfo}
              id={createId.current}
              onSaveClick={onSaveClick}
            ></Preview>
          </div>
        </div>
      </div>
      <ConfirmModal
        ref={ConfirmModalRef}
        confirmFn={() => {
          isUnsavedContent.current = false;
          navigate("/my_agent");
        }}
        noTitleIcon
        title={t("create.unsavedTitle")}
        okText={t("create.unsavedConfirm")}
        danger={false}
        cancelFn={() => {
          isUnsavedContent.current = true;
          store.dispatch(updateFiles({ knowledgeFiles: [], from: "mode" }));
        }}
      >
        {t("create.unsavedContent")}
      </ConfirmModal>
    </div>
  );
};

export default AgentManage;
