import Styles from "./index.module.scss";
import { message, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { IAgentInfoProps } from "@/types/common";
import {
  deletePubAgent,
  toGetAgentInfo,
  toGetAgentPublicInfo,
} from "@/apis/common";
import { useNavigate } from "react-router-dom";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { useTranslation } from "react-i18next";
import useLoginHooks from "@/hooks/useLoginHooks";
import qs from "qs";
import _ from "lodash";
import classnames from "classnames";

const agentBg = createImageUrl("common/agent-bg.png");
const Avatar = createImageUrl("menu/avator.png");

const MyAgentItem = ({
  agentInfo,
  onDelete,
  likeStatusObj,
  userNameObj,
}: IAgentInfoProps) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { isLogin, username, isUserInfoLoading } = useLoginHooks();
  const createSame = () => {
    localStorage.setItem("createAgentInfo", "");
    toGetAgentInfo({
      id: agentInfo.id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          console.log(res.data.data, 890);
          localStorage.setItem("toGetAgentInfo", JSON.stringify(res.data.data));
        }
      }
    });
    navigate(
      `/workflow?${qs.stringify({
        step: "create",
      })}`
    );
  };

  const edit = () => {
    let param: any = {
      step: "create",
      id: String(agentInfo?.id),
      type: "private",
    };
    if (!!agentInfo.pub_agent_id) {
      param.publicId = agentInfo?.pub_agent_id;
    }
    navigate(`/workflow?${qs.stringify(param)}`);
  };

  const deleteItem = () => {
    deletePubAgent({ pub_agent_ids: [agentInfo.id], show_mode: 1 }).then(
      (res) => {
        if (res?.status === 200) {
          onDelete?.(agentInfo);
        } else {
          console.log("失败", res);
        }
      }
    );
  };

  // 跳转逻辑
  const toChat = () => {
    if (agentInfo?.jump_url) {
      window.open(agentInfo?.jump_url);
      return;
    }
    localStorage.setItem("toChatInfo", "");
    toGetAgentPublicInfo({
      id: agentInfo?.id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          const toChatInfo = res.data.data;
          localStorage.setItem("toChatInfo", JSON.stringify(toChatInfo));
          window.open(
            `/Chat?${qs.stringify({
              publicId: agentInfo.id,
              type: "public",
              isDialogue: 1,
              isCollect: likeStatusObj[agentInfo.id || ""]?.is_my_collect,
              email: `@${userNameObj[agentInfo?.author || ""]}`,
            })}`
          );
        }
      }
    });
  };

  return (
    <div className={Styles.agent_item}>
      <div className={Styles.agent_item_content}>
        <div className={Styles.agent_item_img_box}>
          <div className={Styles.agent_item_img_padding}></div>
          <img
            className={Styles.agent_item_img}
            src={getImageUrl(agentInfo?.avatar_url) || agentBg}
            onError={(e: any) => {
              e.target.src = agentBg;
            }}
            onClick={() => {
              toChat();
            }}
          ></img>
          <div className={Styles.agent_item_collect}>
            <Tooltip
              placement="bottom"
              title={"取消收藏"}
              color={"#000"}
              zIndex={10000}
            >
              <div
                className={Styles.iconfont_collect}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteItem();
                }}
              >
                <span
                  className={classnames(
                    "iconfont",
                    "icon-delete_line",
                    "icon-size24"
                  )}
                ></span>
              </div>
            </Tooltip>
          </div>
          <div className={Styles.agent_item_mask}></div>
          {/* <div className={Styles.agent_item_action}>
            <div
              className={Styles.agent_item_action_item}
              onClick={() => {
                edit();
              }}
            >
              <span
                className={classnames(Styles.img, "iconfont", "icon-edit_line")}
              ></span>
              <span>{t("common.edit")}</span>
            </div>
            |
            <div
              className={Styles.agent_item_action_item}
              onClick={() => {
                createSame();
              }}
            >
              <span
                className={classnames(Styles.img, "iconfont", "icon-copy_line")}
              ></span>
              <span>{t("common.create.same")}</span>
            </div>
          </div> */}
        </div>
        {/* <div className={Styles.tag_list}>
        {agentInfo.tags?.map((item, index) => {
          return (
            <div className={Styles.tag_list_item} key={index}>
              {tagNameObj[item]}
            </div>
          );
        })}
      </div> */}
        <div className={Styles.agent_item_info}>
          <img className={Styles.avator} src={Avatar}></img>
          <div>
            <div className={Styles.name}>{agentInfo?.name}</div>
            <div className={Styles.author}>@{username}</div>
          </div>
          {/* <div className={Styles.action}>
          <Button style={{ marginLeft: "20px" }} size="small" onClick={toChat}>
            {t("对话")}
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            size="small"
            onClick={createSame}
          >
            {t("创建同款")}
          </Button>
        </div> */}
        </div>
      </div>
    </div>
    // <div className={Styles.agent_item}>
    //   <img
    //     className={Styles.agent_item_img}
    //     src={getImageUrl(agentInfo?.avatar_url) || agentBg}
    //   ></img>
    //   <div className={Styles.agent_item_info}>
    //     <div>{agentInfo?.name}</div>
    //     <div className={Styles.action}>
    //       <Button size="small" onClick={edit}>
    //         {t("编辑")}
    //       </Button>
    //       <Button
    //         style={{ marginLeft: "20px" }}
    //         size="small"
    //         onClick={createSame}
    //       >
    //         {t("创建同款")}
    //       </Button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default MyAgentItem;
