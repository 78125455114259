import { useEffect, useMemo, useRef, useState } from "react";
import NormalStyles from "./index.module.scss";
import AiSearchStyles from "./aiSearch.module.scss";
import { Button, Input, Tooltip, message, Switch } from "antd";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import {
  toGetAgentPublicInfo,
  toGetAgentInfo,
  getShareUuid,
} from "@/apis/common";
import RefreshPic from "@/assets/chat/refresh.svg";
import logo from "@/assets/chat/logo.svg";
import Typed from "react-typed";

import "react-typed/dist/animatedCursor.css";
import baseWsURL from "@/request/getWsBaseURL";

import SendPic from "@/assets/chat/send.svg";
import SendLightPic from "@/assets/chat/send-light.svg";
import SendLightDisabledPic from "@/assets/chat/send-light-disabled.svg";
import SendDisabledPic from "@/assets/chat/send-disabled.svg";

import modalList from "@/common/modalList";
import useThemeHooks from "@/hooks/useThemeHooks";
import hljs from "highlight.js";
import Cookies from "js-cookie";
import useLoginHooks from "@/hooks/useLoginHooks";
import "highlight.js/styles/vs2015.css";
import Content from "./components/Content";
import Skeleton from "react-loading-skeleton";
import store, { loginOption } from "@/store";
import _, { cloneDeep, set } from "lodash";
import { getJsonConfig } from "@/apis/common";
import conversationPig from "@/assets/common/conversation.svg";
import conversationActivePig from "@/assets/common/conversationActive.svg";
import qs from "qs";
import { useSearchParams, useLocation } from "react-router-dom";
import { getRandomError } from "@/common/errorMsg";
import UploadFilesList from "../uploadFilesList";
import FileUpload from "../FileUpload";
import Workflow from "@/components/ChatWorkflow";
import RelateKnowledgeBaseModal from "@/components/relateKnowledgeBaseModal";
import KnowledgeIcon from "@/assets/special/b_book_line.svg";
import { createImageUrl, isMobile } from "@/utils/common";
import DynamicForm from "../ChatWorkflow/components/DynamicForm";
import ShareModal from "./components/ShareModal";
import { customFileUpload } from "@/utils/upload";

const agentB_proBg = createImageUrl("chat/superAssistant.png");
const aiSearchBg = createImageUrl("common/aiSearch.png");
const AgentIcon1 = createImageUrl("workflow/agent01.png");
const AgentIcon2 = createImageUrl("workflow/agent02.png");
const AgentIcon3 = createImageUrl("workflow/agent03.png");
const AgentIcon4 = createImageUrl("workflow/agent04.png");
const AgentIcon5 = createImageUrl("workflow/agent05.png");
const agentPig = createImageUrl("common/agent-bg.png");

const agentList = [AgentIcon1, AgentIcon2, AgentIcon3, AgentIcon4, AgentIcon5];
type IChatBox = {
  mode: string;
  agentId?: number | string;
  pubAgentId?: number | string;
  firstChat?: any;
  history?: any;
  topicId?: number | string;
  botAvator?: string;
  botName?: string;
  modal?: number;
  modalChoose?: any;
  questionEx?: Array<string>;
  modalId?: number;
  frameId?: number;
  receiverInfo?: any;
  boxStyle?: string;
  homeQuestionList?: any;
  getMainQuestionList?: any;
  currentInfo?: any;
  greeting?: string;
  pageType?: string;
  messageType?: string;
  current?: any;
  refresh?: () => void;
};

let ws: any = null;
let controller = new AbortController();
const ChatBox = ({
  mode,
  agentId,
  pubAgentId,
  firstChat,
  history,
  topicId,
  botAvator,
  botName,
  questionEx,
  frameId,
  boxStyle,
  homeQuestionList,
  currentInfo,
  greeting,
  pageType,
  messageType,
  current,
  refresh,
  receiverInfo,
}: IChatBox) => {
  const { t } = useTranslation();
  const [chatMsg, setChatMsg] = useState("");
  const [sendNow, setSendNow] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [shareTitle, setShareTitle] = useState("");
  const [questionLoading, setQuestionLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isFirstChat, setIsFirstChat] = useState(false);
  const [chatData, setChatData] = useState(history);
  const boxRef: any = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const theme = useThemeHooks();
  const token = localStorage.getItem("token") || Cookies.get("token");
  const [onFocus, setOnFocus] = useState(false);
  const [currentAgent, setCurrentAgent] = useState<any>(
    localStorage.getItem("currentAgent")
      ? JSON.parse(localStorage.getItem("currentAgent") as string)
      : {}
  );

  const [deleteFileList, setDeleteFileList] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [pastedFiles, setPastedFiles] = useState<any>([]);

  const [fileUploadKey, setFileUploadKey] = useState(0);
  const [isHistoryWokeFlow, setIsHistoryWokeFlow] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isShowDynamicForm, setIsShowDynamicForm] = useState(false);
  const [userInputForm, setUserInputForm] = useState(Number);

  const clearDynamicForm = () => {
    setIsShowDynamicForm(false);
  };

  const getFilesList = (fileArr: any) => {
    setFileList(fileArr);
  };

  const chatFiles = useMemo(() => {
    return [...fileList, ...pastedFiles].filter((file: any) => {
      return !deleteFileList.some((item: any) => item.uid === file.uid);
    });
  }, [fileList, pastedFiles, deleteFileList]);

  const [bigAgentList, setBigAgentList] = useState([
    {
      show_name: "关联知识库",
      icon: KnowledgeIcon,
    },
  ]);

  const [knowledgeBaseSearch, setKnowledgeBaseSearch] = useState(true);
  const [webSearch, setWebSearch] = useState(true);
  const [knowledgeList, setKnowledgeList] = useState([]);
  const [isShowWork, setIsShowWork] = useState(false);

  const { isLogin } = useLoginHooks();
  const relateKnowledgeBaseModalRef = useRef<any>();
  const location = useLocation();

  const clearWoke = (fileArr: any) => {
    setIsShowWork(false);
  };

  const handleShare = (value: boolean) => {
    // 传入参数则打开分享弹窗 不穿参数或者传入false则关闭弹窗
    if (value) {
      getShareUuid(value).then((res) => {
        if (res.data?.status === 200) {
          const currentLocation = window.location;
          const baseUrl = `${currentLocation.protocol}//${
            currentLocation.hostname
          }${currentLocation.port ? `:${currentLocation.port}` : ""}`;
          const shareUrl = `${baseUrl}/share?uuid=${res.data.data.uuid}`;
          setShareUrl(shareUrl);
          setShareTitle(res.data.data.chat_title);
        } else {
          message.error("生成链接失败啦, 请稍后重试~");
        }
      });
      setIsShareModalVisible(true);
    } else {
      setIsShareModalVisible(false);
      setShareUrl("");
      setShareTitle("");
    }
  };

  let isFinished = true;
  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };

  const Styles = boxStyle === "ai_search" ? AiSearchStyles : NormalStyles;

  const [params] = useSearchParams();

  const tryAgain = (index: number, item?: any) => {
    const data = [...chatData].slice(0, index);
    const lastMsg = _.findLast(data, (item) => item.role === 1);

    // 如果是工作流
    if (item.content_type === "tool") {
      chatWorkflowSend();
      return;
    }

    if (lastMsg.role === 1) {
      // setChatData(chatData.slice(0, chatData.length - 1));
      if (typeof lastMsg.content === "string") {
        setChatMsg(lastMsg.content);
        setSendNow(true);
        goChatBottom();
        return;
      }

      if (Array.isArray(lastMsg.content)) {
        setChatMsg(lastMsg.content[0].text);
        setFileList(
          lastMsg.content.slice(1).map((item: any) => {
            return {
              ...item,
              response: { data: { file_url: item?.url } },
            };
          })
        );
        setTimeout(() => {
          setSendNow(true);
          goChatBottom();
        }, 100);
      }
    }
  };

  const chatWorkflowSend = (topic_id?: string | number) => {
    const data = localStorage.getItem("chatWorkFlowData")
      ? JSON.parse(localStorage.getItem("chatWorkFlowData") as string)
      : {};
    if (data.id) {
      setIsHistoryWokeFlow(false);
    }
    const state = location.state;
    const curTopicId = topic_id || topicId;
    const newChatData = [...chatData];
    const inputs = data.inputs;

    const originData = data?.originData || [];
    const result = originData
      .map((item: any) => {
        const variable = item.variable;
        if (inputs[variable] !== undefined) {
          return `${item.label}: ${inputs[variable]}`;
        } else {
          return `${item.label}: `;
        }
      })
      .filter((item: any) => item !== "")
      .join(";\n");

    let msgObj: any = {
      message: "",
      topic_id: curTopicId,
      history: newChatData.slice(0, newChatData.length).slice(-10),
      is_save_msg: token ? true : false,
      chat_mode: "ai_workflow",
      is_stream: true,
      workflow: {
        // 如果使用了工作流，就传此参数。否则不传或传 {}
        id: data.id, // Django中workflow的自增ID
        inputs, // dify 工作流的参数
      },
      model: currentAgent.model_name,
    };

    // 超级助理是专家进来对话，弹出啊工作流，增加 message 内容字段传参，放弃 workflow 字段传参。
    if (state?.pattern === "super_assistant") {
      msgObj.message = result;
    }

    newChatData.push(
      {
        role: 1,
        content: result,
      },
      {
        role: 2,
        content: " ",
        isLoading: true,
      }
    );

    setChatData(newChatData);
    clearDynamicForm();
    if (ws && ws.readyState === 1) {
      //开始通信时的处理
      ws.send(JSON.stringify(msgObj));
    } else {
      unConnectWs();
      setTimeout(() => {
        connectWs(() => {
          ws && ws.send(JSON.stringify(msgObj));
        });
      });
    }
  };

  useEffect(() => {
    setChatData(history);
  }, [history]);

  useEffect(() => {
    if (!!sendNow) {
      sendMsg();
      setSendNow(false);
    }
  }, [sendNow]);

  const chatMsg1 = localStorage.getItem("chatMsg");
  useEffect(() => {
    if (pageType === "superAssistant" && messageType === "workflow") {
      unConnectWs();
      connectWs(() => {
        if (firstChat) {
          firstChat().then((res: any) => {
            if (res && res.id) {
              chatWorkflowSend(res.id);
            }
          });
        }
      });

      return;
    }
    if (chatMsg1 && pageType === "superAssistant") {
      unConnectWs();
      connectWs(() => {
        setChatMsg(chatMsg1);
        setSendNow(true);
        // localStorage.removeItem("chatMsg");
      });
    }
  }, [chatMsg1, pageType, messageType]);

  useEffect(() => {
    if (boxStyle === "ai_search" && isLogin) {
      unConnectWs();
      setTimeout(() => {
        connectWs();
      });
    }
  }, [boxStyle]);

  const curTopicId = params.getAll("topicId")?.[0];
  useEffect(() => {
    if (!curTopicId) return;
    unConnectWs();
    connectWs();
  }, [curTopicId]);

  const highlightCode = () => {
    setTimeout(() => {
      if (boxRef?.current && boxRef?.current?.querySelectorAll) {
        const preEl = boxRef?.current?.querySelectorAll("pre code");
        preEl.forEach((el: any) => {
          hljs.highlightElement(el);
        });
      }
    }, 0);
  };

  const onStopChatClick = () => {
    unConnectWs();
    setBtnLoading(false);
    const newChatData = [...chatData];
    newChatData.forEach((item: any) => {
      item.isLoading = false;
      // try {
      //   if (
      //     item?.role === 2 &&
      //     typeof item?.content !== "string" &&
      //     !!item?.content?.steps?.length &&
      //     item?.content?.steps?.[item?.content?.steps?.length - 1]?.status ===
      //       "running"
      //   ) {
      //     item.content.steps[item?.content?.steps?.length - 1].status =
      //       "stopped";
      //     item.content.result_status = "stopped";
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    });
    setChatData(newChatData);
    highlightCode();
    setTimeout(() => {
      connectWs();
    }, 200);
  };

  const connectWs = (func?: any) => {
    const token_string = `?token=${token}`;
    ws = new WebSocket(
      `${baseWsURL}/ws/agent_chat${token ? token_string : ""}`
    );

    ws.onopen = function (event: any) {
      if (func) {
        func();
        setIsFirstChat(false);
      }
    };

    ws.onclose = function (event: any) {
      setIsFirstChat(false);
      setBtnLoading(false);
      console.log("wsclose");
      highlightCode();
    };

    ws.onmessage = (res: any) => {
      const data = JSON.parse(res.data);
      setBtnLoading(true);
      if (data.status === 40001) {
        setBtnLoading(false);

        onLoginAndRegister();
      } else if (data.status === 40000) {
        setChatData((chatData: any) => {
          const newChatData = [...chatData];
          newChatData.pop();
          message.error("参数错误");
          return newChatData;
        });
      } else if (data.status === 20052) {
        // 工作流弹窗
        setIsShowDynamicForm(true);
        setUserInputForm(data.user_input_form);
        setBtnLoading(false);
        highlightCode();
        setChatData((chatData: any) => {
          const newChatData = [...chatData];
          newChatData.pop();
          return newChatData;
        });
      } else if (data.status === 200 && data.from !== "userproxy" && data) {
        let { is_finished, content, content_type } = data;
        if (isFinished) {
          setChatData((chatData: any) => {
            const newChatData = [...chatData];
            // 如果上一条数据为 is_finished，追加一条新的消息展示
            if (
              newChatData[newChatData.length - 1].is_finished ||
              mode === "preview"
            ) {
              newChatData.push({
                ...data,
                content_type,
                isLoading: false,
              });
            } else {
              newChatData[newChatData.length - 1] = {
                ...data,
                content_type,
                isLoading: false,
              };
            }
            // newChatData.push({ ...data, content_type });
            return newChatData;
          });
          isFinished = is_finished;
        } else {
          if (content_type === "text") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: newChatData[newChatData.length - 1].content + content,
                content_type,
                isLoading: true,
              };
              return newChatData;
            });
          } else if (content_type === "search") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: {
                  ...data?.content,
                  answer:
                    newChatData[newChatData.length - 1]?.content?.answer +
                    data?.content?.answer,
                  content_type,
                },
                isLoading: true,
              };
              return newChatData;
            });
          } else if (content_type === "tool") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: {
                  ...data?.content,
                  result:
                    newChatData[newChatData.length - 1]?.content?.result +
                    data?.content?.result,
                  content_type,
                },
                isLoading: true,
              };
              return newChatData;
            });
          }
          isFinished = is_finished;
        }
      }

      if ((data.status && data.status === 20050) || data.status === 50001) {
        setChatData((chatData: any) => {
          const newChatData = [...chatData];
          newChatData.forEach((item: any) => {
            item.isLoading = false;
          });
          const temp = cloneDeep(newChatData[newChatData.length - 1]);
          if (typeof temp?.content === "string" && temp.content.trim() === "") {
            newChatData.pop();
          } else {
            newChatData[newChatData.length - 1] = {
              ...temp,
              isLoading: false,
            };
          }
          return newChatData;
        });
        highlightCode();
        setBtnLoading(false);
      }

      if (data.status && data.status === 50001) {
        const errorMsg = getRandomError();
        message.error(errorMsg);
      }

      // hljs.initHighlighting();
    };

    ws.onerror = function (error: any) {
      setIsFirstChat(false);
      setBtnLoading(false);
      console.log("wserror", error);
    };
    // }
    // }
    // else {
    //   if (token) {
    //     ws = new WebSocket(
    //       `${baseWsURL}aichat/agent/edit/chat/ws?token=${token}`
    //     );

    //     ws.onopen = function (event: any) {
    //       if (func) {
    //         func();
    //         setIsFirstChat(false);
    //       }
    //     };

    //     ws.onerror = function (error: any) {
    //       setIsFirstChat(false);
    //     };
    //   }
    // }
  };

  const addConversation = () => {
    // localStorage.setItem("toChatInfo", "");
    const publicId = Number(params.getAll("publicId")?.[0]) || 0;
    const email = params.getAll("email")?.[0] || "";
    const isCollect = params.getAll("isCollect")?.[0] || "";
    const type = params.getAll("type")?.[0] || "";
    const agentId = Number(params.getAll("agentId")?.[0]) || 0;

    let api = toGetAgentPublicInfo;
    if (type === "private") {
      api = toGetAgentInfo;
    }
    api({
      id: type === "private" ? Number(agentId) : Number(publicId),
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          const toChatInfo = res.data.data;
          localStorage.setItem("toChatInfo", JSON.stringify(toChatInfo));
          let data: any = {
            type,
            isDialogue: 1,
            isCollect,
            email,
            pageType: "addChat",
            ...(type === "private" ? { agentId } : { publicId }),
          };
          window.open(`/Chat?${qs.stringify(data)}`);
        }
      }
    });
  };

  const unConnectWs = () => {
    if (ws) {
      ws.close();
    }
  };

  // useEffect(() => {
  //   highlightCode()
  // }, [chatData]);

  useEffect(() => {
    if (agentId) {
      controller.abort();
      controller = new AbortController();
    }
  }, [agentId]);

  useEffect(() => {
    // 是否关联知识库，获取超级助理传递过来的参数
    const state = location.state;
    if (state && state.knowledge) {
      setKnowledgeList(JSON.parse(state.knowledge));
    }

    if (pageType === "superAssistant" || !isLogin) {
      return;
    }

    // 先断开ws再重新连接
    if (ws) {
      unConnectWs();
    }

    connectWs();

    return () => {
      unConnectWs();
    };
    // }
  }, []);

  // const getConfig = async () => {
  //   getJsonConfig({
  //     keys: ["super_assistant_model_list"],
  //   }).then((res) => {
  //     if (res?.status === 200 && res?.data?.data?.super_assistant_model_list) {
  //       setBigAgentList(res.data.data.super_assistant_model_list);
  //     }
  //   });
  // };

  useEffect(() => {
    const data = localStorage.getItem("chatWorkFlowData")
      ? JSON.parse(localStorage.getItem("chatWorkFlowData") as string)
      : {};
    if (!data.id) {
      setIsHistoryWokeFlow(true);
    }
    // getConfig();
  }, []);

  const goChatBottom = () => {
    if (boxRef?.current) {
      boxRef.current.scrollTop = boxRef?.current?.scrollHeight;
    }
  };

  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  useEffect(() => {
    const onScroll = () => {
      const { scrollTop } = boxRef?.current;
      if (scrollTop > lastScrollTop) {
        setIsScrollingUp(false); // 用户向下滚动
      } else {
        setIsScrollingUp(true); // 用户向上滚动
      }
      setLastScrollTop(scrollTop);
    };

    boxRef.current.addEventListener("scroll", onScroll);

    return () => {
      boxRef && boxRef?.current?.removeEventListener("scroll", onScroll);
    };
  }, [lastScrollTop]);

  const [isOnBottom, setIsOnBottom] = useState(false);

  useEffect(() => {
    setIsOnBottom(true);
    if (boxRef?.current && boxRef.current?.scrollTop !== undefined) {
      boxRef.current.scrollTop = boxRef.current?.scrollHeight;
    }
  }, [agentId, pubAgentId]);

  useEffect(() => {
    const handleScrollFn = () => {
      const { scrollTop, scrollHeight, clientHeight } = boxRef?.current;
      const errorTolerance = 80; // 设置一个误差容忍值
      if (
        errorTolerance >= Math.abs(scrollTop - (scrollHeight - clientHeight))
      ) {
        setIsOnBottom(true); // 此时已经在底部了
      } else {
        setIsOnBottom(false);
      }
    };

    boxRef?.current?.addEventListener("scroll", handleScrollFn);

    return () => {
      boxRef && boxRef?.current?.removeEventListener("scroll", handleScrollFn);
    };
  }, []);
  const state = location.state;
  let superType = "llm_chat";
  if (pageType === "superAssistant" && state?.pattern === "super_assistant") {
    superType = "super_assistant";
  }

  const chatMode =
    boxStyle === "ai_search"
      ? "ai_search"
      : pageType === "superAssistant" || frameId === 101
      ? superType
      : mode === "advanced"
      ? "ai_study"
      : "ai_agent";

  useEffect(() => {
    let boxScrollBottom: any = null;
    // 根据滚动方向进行相应处理

    if (!isScrollingUp && isOnBottom) {
      if (boxRef?.current) {
        boxScrollBottom = setInterval(() => {
          if (
            boxRef.current?.scrollTop === undefined ||
            boxRef.current?.scrollTop === null
          ) {
            return;
          }
          boxRef.current.scrollTop = boxRef.current?.scrollHeight;
        }, 100);
      }
    } else {
      clearInterval(boxScrollBottom);
    }

    return () => {
      if (boxScrollBottom) {
        clearInterval(boxScrollBottom);
      }
    };
  }, [chatData, isScrollingUp, isOnBottom]);

  const sendMsg = () => {
    if (btnLoading) {
      return;
    }

    if (!chatMsg) {
      return false;
    }

    if (!token) {
      onLoginAndRegister();
      return;
    }

    const newChatData = [...chatData];

    const sendMsgWs = (id?: any) => {
      // llm_chat 简洁和  assistant 专家模式
      if (
        pageType === "superAssistant" &&
        state?.pattern === "super_assistant"
      ) {
      }
      let msgObj: any = {
        message: chatMsg,
        topic_id: id ? id : topicId,
        history: newChatData.slice(0, newChatData.length).slice(-10),
        is_save_msg: token ? true : false,
        chat_mode: chatMode,
        is_stream: true,
      };

      if (mode === "advanced") {
        msgObj = {
          history: newChatData.slice(0, newChatData.length), // 历史聊天记录
          message: chatMsg,
          chat_mode: chatMode,
        };
      }

      const type = params.getAll("type")?.[0] || "";
      if (pageType === "addChat") {
        msgObj.is_public = type === "public";
        msgObj.agent_id = type === "public" ? current.id : agentId;
      } else if (mode === "agent") {
        msgObj.is_public = false;
        msgObj.agent_id = agentId;
      } else if (mode === "pub_agent") {
        msgObj.is_public = true;
        msgObj.agent_id = pubAgentId;
      }

      if (boxStyle === "ai_search") {
        msgObj.enable_kb_search = knowledgeBaseSearch;
        msgObj.enable_web_search = webSearch;
      }

      if (pageType === "superAssistant") {
        msgObj.model = currentAgent.model_name;
        const curChatFiles = localStorage.getItem("chatFiles")
          ? JSON.parse(localStorage.getItem("chatFiles") as string)
          : [];
        msgObj.message = [
          {
            type: "text",
            text: chatMsg,
          },
          ...curChatFiles
            .concat(chatFiles)
            .filter(
              (item: any) =>
                item.status === "done" &&
                item.response?.data &&
                item.response?.data.file_url
            )
            .map((item: any) => {
              const fileType = item.name.substring(item.name.lastIndexOf("."));
              return {
                name: item.name,
                status: item.status,
                type: [".jpg", ".jpeg", ".png", ".webp"].includes(fileType)
                  ? "image_url"
                  : [
                      ".pdf",
                      ".docx",
                      ".xlsx",
                      ".pptx",
                      ".txt",
                      ".md",
                      ".csv",
                    ].includes(fileType)
                  ? "file_url"
                  : [".mp3", ".wav"].includes(fileType)
                  ? "audio_url"
                  : "video_url",
                url: item.response?.data?.file_url,
                fmt: fileType,
              };
            }),
        ];
        localStorage.removeItem("chatFiles");
      }

      if (`${frameId}` === "101") {
        msgObj.model = receiverInfo?.model;
        msgObj.system_message = receiverInfo?.system_message;
      }

      // 是否关联知识库，增加
      if (knowledgeList.length > 0) {
        let result = knowledgeList.map(({ name, ...rest }: any) => rest);
        msgObj.knowledge_folder = result;
      }

      newChatData.push(
        {
          role: 1,
          content: msgObj?.message,
        },
        {
          role: 2,
          content: " ",
          isLoading: true,
        }
      );

      const sendAction = () => {
        if (ws.readyState === 1) {
          //开始通信时的处理
          ws.send(JSON.stringify(msgObj));
        }
      };

      if (ws && ws.readyState === 1) {
        sendAction();
      } else {
        unConnectWs();
        setTimeout(() => {
          connectWs(() => {
            sendAction();
          });
        });
      }
    };

    const sendAction = () => {
      newChatData.push({
        role: 1,
        content: chatMsg,
      });

      let msgObj: any = {
        message: chatMsg,
        history: newChatData.slice(1).slice(-10),
        is_preview: true,
        chat_mode: chatMode,
        agent_id: agentId,
      };
      if (mode === "advanced") {
        msgObj = {
          history: newChatData.slice(0, newChatData.length), // 历史聊天记录
          message: chatMsg,
          chat_mode: chatMode, // ai_search or ai_agent
        };
      }

      ws.send(JSON.stringify(msgObj));
    };

    if (mode === "preview") {
      if (ws && ws.readyState === 1) {
        //开始通信时的处理
        sendAction();
      } else {
        unConnectWs();
        setTimeout(() => {
          connectWs(() => {
            sendAction();
          });
        });
      }
    } else {
      if (typeof topicId === "string" && topicId.includes("newChat")) {
        if (firstChat) {
          setIsFirstChat(true);
          firstChat().then((res: any) => {
            if (res && res.id) {
              sendMsgWs(res.id);
            }
          });
        }
      } else {
        sendMsgWs();
      }
    }

    setChatData(newChatData);

    setChatMsg("");
    setFileList([]);
    setDeleteFileList([]);
    setFileUploadKey((key) => key + 1);
  };

  const getSendImgSrc = () => {
    return !chatMsg ? SendLightDisabledPic : SendLightPic;
  };

  const [relatedClick, setRelatedClick] = useState(false);
  const onRelatedClick = (message: string) => {
    setChatMsg(message);
    setRelatedClick((value) => !value);
    goChatBottom();
  };

  const getAvatarUrl = (name: string) => {
    const receiver = currentInfo?.flow_config?.receiver || [];
    return currentInfo?.id === 1000 ||
      !name ||
      Object.keys(currentInfo || {}).length === 0
      ? botAvator
      : receiver.filter((item: any) => item?.name === name)?.[0]?.avatar_url ||
          agentList[
            receiver.findIndex((item: any) => item.name === name) === -1
              ? 0
              : receiver.findIndex((item: any) => item.name === name)
          ];
  };

  const QuestionSkeleton = () => {
    return (
      <div
        style={{
          padding: "0",
        }}
      >
        <Skeleton height={24} width={100} />
        <Skeleton
          height={24}
          style={{
            marginTop: "12px",
          }}
        />
        <Skeleton
          width={100}
          height={24}
          style={{
            marginTop: "24px",
          }}
        />
        <Skeleton
          style={{
            marginTop: "12px",
          }}
          height={90}
        />
        <Skeleton
          width={100}
          height={24}
          style={{
            marginTop: "24px",
          }}
        />
        <Skeleton
          style={{
            marginTop: "12px",
          }}
          height={120}
        />
      </div>
    );
  };

  useEffect(() => {
    sendMsg();
  }, [relatedClick]);

  useEffect(() => {
    if (chatData?.length) {
      const clickHandler = (event: any) => {
        if (!event.target.getAttribute("href")) {
          return;
        }
        // 阻止 a 标签点击事件的默认行为，即页面跳转
        event.preventDefault();
        window.open(event.target.getAttribute("href"), "_blank");
      };

      var content: any = document.getElementById("history");

      const aTags = content.getElementsByTagName("a");

      // 借用 Array.prototype.forEach 遍历处理 选到的 a 标签
      Array.prototype.forEach.bind(aTags)((a) => {
        a.addEventListener("click", clickHandler);
      });
    }
  }, [chatData?.length]);

  const handleLinkClick = () => {
    relateKnowledgeBaseModalRef?.current?.show();
  };

  return (
    <div
      className={classnames(
        Styles.chat,
        ["ai_search", "preview", "card", "advanced"].includes(boxStyle!)
          ? Styles.chat_preview
          : "",
        ["card"].includes(boxStyle!) ? Styles.chat_card : "",
        ["ai_search"].includes(boxStyle!) ? Styles.chat_home_page : "",
        ["advanced"].includes(boxStyle!) ? Styles.chat_advanced : ""
      )}
      style={
        ["ai_search"].includes(boxStyle!) && !chatData.length
          ? {
              height: "fit-content",
            }
          : {}
      }
    >
      {isShareModalVisible ? (
        <ShareModal
          open={isShareModalVisible}
          setOpen={handleShare}
          url={shareUrl}
          shareTitle={shareTitle}
        />
      ) : null}
      <div
        className={Styles.chat_inner}
        style={
          boxStyle === "card"
            ? {
                minWidth: "auto",
              }
            : {}
        }
      >
        <div
          className={Styles.chat_history}
          ref={boxRef}
          style={
            boxStyle === "ai_search" && chatData.length
              ? {
                  overflowY: "scroll",
                }
              : {}
          }
        >
          {chatData && !!chatData.length ? (
            <div
              className={Styles.chat_history_inner}
              id="history"
              style={
                pageType === "superAssistant" &&
                chatFiles.length &&
                chatFiles.length > 3
                  ? {
                      marginBottom: "150px",
                    }
                  : chatFiles.length > 6
                  ? { marginBottom: "200px" }
                  : {}
              }
            >
              {chatData.map((item: any, index: number) => {
                return (
                  <div
                    className={classnames(
                      Styles.chat_history_item,
                      Styles.chat_history_item_receive
                    )}
                    key={index}
                  >
                    {item.role === 2 ? (
                      <div className={Styles.chat_history_name_box}>
                        <img
                          className={Styles.chat_history_item_img}
                          src={
                            pageType === "superAssistant"
                              ? agentB_proBg
                              : boxStyle === "ai_search"
                              ? aiSearchBg
                              : ((item?.from || item?.sender_name) &&
                                  getAvatarUrl(
                                    item?.from || item?.sender_name
                                  )) ||
                                botAvator ||
                                agentPig
                          }
                          loading="lazy"
                        ></img>
                        <div>
                          {item?.from || item?.sender_name || botName || ""}
                        </div>
                      </div>
                    ) : null}
                    {item.content && (
                      <div className={Styles.chat_history_item_msg}>
                        {item.role === 2 ? (
                          <div
                            className={Styles.chat_history_item_content_wrap}
                            style={{
                              whiteSpace: "pre-wrap",
                              width: "calc(100% - 32px)",
                            }}
                          >
                            <Content
                              handleSetIsShareModalVisible={handleShare}
                              curWidth={boxRef.current?.clientWidth}
                              message={item}
                              isHistoryWokeFlow={isHistoryWokeFlow}
                              onRelatedClick={onRelatedClick}
                              isLast={index === chatData.length - 1}
                              tryAgain={() => {
                                tryAgain(index, item);
                              }}
                              btnLoading={item.isLoading}
                              topicId={topicId}
                              chatMode={chatMode}
                              showShareBtn={pageType === "superAssistant"}
                            />
                          </div>
                        ) : (
                          <div className={Styles.chat_history_item_wrapper}>
                            <div className={Styles.chat_history_user_msg_item}>
                              <div className={Styles.chat_history_item_inner}>
                                <div
                                  style={{
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {typeof item.content === "string" ? (
                                    item.content
                                  ) : (
                                    <>
                                      {(() => {
                                        return Array.isArray(item?.content)
                                          ? item.content.find(
                                              (subItem: any) =>
                                                subItem.type === "text"
                                            )?.text
                                          : typeof item.content === "object"
                                          ? JSON.stringify(item.content)
                                          : item.content;
                                      })()}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            {Array.isArray(item?.content) && (
                              <div
                                className={Styles.chat_history_user_msg_item}
                                style={{ justifyContent: "flex-start" }}
                              >
                                {item?.content?.filter(
                                  (subItem: any) => subItem.type !== "text"
                                ).length ? (
                                  <div
                                    className={Styles.filesList}
                                    style={{
                                      position: "relative",
                                      top: "16px",
                                    }}
                                  >
                                    <UploadFilesList
                                      fileList={item?.content?.filter(
                                        (subItem: any) =>
                                          subItem.type !== "text"
                                      )}
                                      isChat={true}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
              {questionLoading && <QuestionSkeleton />}
            </div>
          ) : (
            <div
              className={classnames(
                Styles.empty,
                ["card", "advanced"].includes(boxStyle!)
                  ? Styles.empty_card
                  : ""
              )}
            >
              {["card", "advanced"].includes(boxStyle!) && (
                <>
                  <img
                    src={botAvator}
                    className={Styles.card_empty_img}
                    loading="lazy"
                  ></img>
                  <div className={Styles.card_empty_msg}>
                    {greeting || t("关于左侧的知识有什么可以帮你吗？")}
                  </div>
                </>
              )}
              {pageType !== "superAssistant" &&
                (boxStyle === "chat" || pageType === "addChat") && (
                  <>
                    <div className={Styles.assistant_box}>
                      <div className={Styles.assistant_box_top}>
                        <img src={botAvator || agentPig} alt="" />
                        <div>{current?.name}</div>
                      </div>
                      <div className={Styles.assistant_box_middle}>
                        {current?.greeting ||
                          t("common.chat.default.greetings")}
                      </div>
                      {!!questionEx && (
                        <div className={Styles.assistant_box_questions}>
                          {questionEx.map((item: any, index: any) => {
                            return (
                              item.trim() && (
                                <div
                                  className={Styles.assistant_box_question_item}
                                  key={index}
                                  onClick={() => {
                                    setChatMsg(item);
                                    setSendNow(true);
                                  }}
                                >
                                  {item}
                                </div>
                              )
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </>
                )}
              {boxStyle === "ai_search" && (
                <div className={Styles.empty_home_page}>
                  <div className={Styles.empty_home_page_title}>
                    <div className={Styles.empty_home_page_big_title}>
                      <img src={logo} width={52} />
                      <span className={Styles.empty_home_page_green}>
                        SyngentsAI搜索
                      </span>
                    </div>
                    <div
                      className={classnames(
                        Styles.empty_home_page_desc,
                        "magictime",
                        "spaceInDown"
                      )}
                    >
                      <Typed
                        strings={[
                          "知识+联网，给你最优结果",
                          // "提供专业的解决方案和AI工具",
                          // "解答您遇到的一切问题",
                          // "记住一切，并随时启发您",
                          // "新智Syngents助手",
                        ]}
                        loop
                        typeSpeed={40}
                        startDelay={1000} // 开始打字前的延迟时间
                        backDelay={2000} // 开始删除文本前的延迟时间
                      />
                    </div>
                  </div>
                  <div>
                    <div className={Styles.empty_home_page_recommand}>
                      <div className={Styles.questions_title}>
                        <span>试着问一下这些问题</span>
                        <div
                          className={Styles.questions_refresh}
                          onClick={refresh}
                        >
                          <div
                            className={classnames(
                              Styles.img,
                              "icon-again_line",
                              "iconfont"
                            )}
                          ></div>
                          换一换
                        </div>
                      </div>
                      {homeQuestionList.map((item: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className={classnames(
                              Styles.empty_home_page_recommand_item,
                              "magictime",
                              isReload
                                ? index % 2 === 0
                                  ? "spaceInLeft"
                                  : "spaceInRight"
                                : "vanishIn"
                            )}
                            style={{
                              animationDelay: isReload
                                ? "unset"
                                : `${0.2 * index}s`,
                              animationDuration: isReload ? "0.5s" : "1s",
                            }}
                            onClick={() => {
                              setQuestionLoading(true);
                              setChatData([
                                {
                                  role: 1,
                                  content: item.question,
                                },
                                {
                                  role: 2,
                                  content: "",
                                },
                              ]);
                              setTimeout(() => {
                                setChatData(
                                  item.answer
                                    ? [
                                        {
                                          role: 1,
                                          content: item.question,
                                        },
                                        ...item.answer,
                                      ]
                                    : []
                                );
                                setQuestionLoading(false);
                              }, 3000);
                            }}
                          >
                            <div
                              className={Styles.empty_home_page_recommand_info}
                            >
                              <span>{item.question}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={`${Styles.chat_insert} ${
            boxStyle === "ai_search" && !chatData.length
              ? Styles.chat_insert_center
              : ""
          }`}
        >
          {pageType !== "superAssistant" && (
            <div
              className={Styles.chat_to_bottom}
              style={{ visibility: isOnBottom ? "hidden" : "visible" }}
            >
              <img
                className={Styles.chat_insert_send}
                src={theme === "dark" ? SendDisabledPic : SendLightDisabledPic}
                onClick={() => {
                  goChatBottom();
                }}
                loading="lazy"
              ></img>
            </div>
          )}
          {pageType === "superAssistant" ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                bottom: !!chatFiles.length
                  ? isMobile
                    ? "80px"
                    : chatFiles.length <= 3
                    ? "100px"
                    : chatFiles.length <= 6
                    ? "150px"
                    : "215px"
                  : "0",
              }}
            >
              <div className={`${Styles.chat_super_assistant_wrap}`}>
                <div
                  className={classnames(
                    Styles.chat_input_box_wrap,
                    isMobile ? Styles.mobileChatInput : ""
                  )}
                  style={isMobile ? { overflow: "unset" } : {}}
                >
                  {!isMobile ? (
                    <div className={Styles.chat_input_active}>
                      <Input.TextArea
                        onFocus={() => {
                          setOnFocus(true);
                        }}
                        onBlur={() => {
                          setOnFocus(false);
                        }}
                        onPaste={(event: any) => {
                          const items = event.clipboardData.items;
                          for (let i = 0; i < items.length; i++) {
                            if (items[i].kind === "file") {
                              const file = items[i].getAsFile();
                              if (file && file.type.startsWith("image/")) {
                                customFileUpload({
                                  file,
                                  onSuccess: (data: any) => {
                                    const isLt100M =
                                      file.size / 1024 / 1024 < 100;
                                    const currentFile = {
                                      name: file.name,
                                      uid: data.data.file_url,
                                      url: data.data.file_url,
                                      size: file.size,
                                      response: data,
                                      status: isLt100M ? "done" : "error",
                                    };

                                    setPastedFiles([
                                      ...pastedFiles,
                                      currentFile,
                                    ]);
                                    message.success("图片上传成功");
                                  },
                                  onError: (error: any) => {
                                    message.error("图片上传失败，请稍后重试");
                                  },
                                });
                              }
                            }
                          }
                        }}
                        className={Styles.chat_insert_input}
                        value={chatMsg}
                        autoSize={{ minRows: 2, maxRows: 5 }}
                        onChange={(e) => {
                          setChatMsg(e.target.value);
                        }}
                        style={{ background: "transparent" }}
                        placeholder={t("询问您的助理一切问题")}
                        onPressEnter={(e) => {
                          if (e.which === 13 || e.keyCode === 13) {
                            if (e.shiftKey) {
                              return;
                            }
                            if (!isTyping) {
                              sendMsg();
                            }
                            e.preventDefault();
                          }
                        }}
                        onCompositionStart={() => {
                          setIsTyping(true);
                        }}
                        onCompositionEnd={() => {
                          setIsTyping(false);
                        }}
                      ></Input.TextArea>
                      <div className={Styles.chat_insert_right}>
                        <div
                          className={classnames(
                            Styles.chat_workflow,
                            "iconfont",
                            "icon-size24",
                            "icon-classify_line"
                          )}
                          onClick={(e) => {
                            setIsShowWork(true);
                            e.preventDefault();
                          }}
                        ></div>
                        <div className={Styles.chat_upload}>
                          <FileUpload
                            isMultiple={true}
                            onUploadSuccess={() => {}}
                            getFilesList={getFilesList}
                            isCustom
                            folderPath={"chat"}
                            keys={fileUploadKey}
                            maxSize={20}
                          >
                            <Tooltip
                              title={
                                "支持上传文件(最多20个，每个20MB)支持png、jpg、pdf、doc、docx、xlsx、ppt、txt。"
                              }
                            >
                              <div
                                className={classnames(
                                  Styles.chat_upload_d,
                                  "iconfont",
                                  "icon-size24",
                                  "icon-a-paperclip_line"
                                )}
                                style={{ cursor: "pointer" }}
                              ></div>
                            </Tooltip>
                          </FileUpload>
                        </div>
                        <img
                          className={Styles.chat_insert_send}
                          src={getSendImgSrc()}
                          onClick={(e) => {
                            if (!isTyping) {
                              sendMsg();
                            }
                            e.preventDefault();
                          }}
                          loading="lazy"
                        ></img>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          height: "36px",
                          gap: "12px",
                        }}
                      >
                        {bigAgentList.map((item: any, index) => {
                          return (
                            <Button
                              key={index}
                              className={
                                Styles.agentBtn
                                // item.model_name === currentAgent.model_name
                                //   ? Styles.agentBtnActive
                                //   : Styles.agentBtn
                              }
                              onClick={() => {
                                handleLinkClick();
                                // setCurrentAgent(item);
                                // localStorage.setItem(
                                //   "currentAgent",
                                //   JSON.stringify(item)
                                // );
                              }}
                            >
                              <div className={Styles.agentBtnContent}>
                                <img
                                  src={item.icon}
                                  className={Styles.agentIcon}
                                />
                                {item.show_name}
                                {knowledgeList.length > 0 ? (
                                  <span className={Styles.agentBtnNumbers}>
                                    {knowledgeList.length}
                                  </span>
                                ) : null}
                              </div>
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={Styles.chat_input_active}>
                        <Input.TextArea
                          onFocus={() => {
                            setOnFocus(true);
                          }}
                          onBlur={() => {
                            setOnFocus(false);
                          }}
                          className={Styles.chat_insert_input}
                          value={chatMsg}
                          autoSize={{ minRows: 2, maxRows: 5 }}
                          onChange={(e) => {
                            setChatMsg(e.target.value);
                          }}
                          style={{ background: "transparent" }}
                          placeholder={t("询问您的助理一切问题")}
                          onPressEnter={(e) => {
                            if (e.which === 13 || e.keyCode === 13) {
                              if (e.shiftKey) {
                                return;
                              }
                              if (!isTyping) {
                                sendMsg();
                              }
                              e.preventDefault();
                            }
                          }}
                          onCompositionStart={() => {
                            setIsTyping(true);
                          }}
                          onCompositionEnd={() => {
                            setIsTyping(false);
                          }}
                        ></Input.TextArea>
                        <div className={Styles.chat_insert_right}>
                          <div
                            className={classnames(
                              Styles.chat_workflow,
                              "iconfont",
                              "icon-size24",
                              "icon-classify_line"
                            )}
                            onClick={(e) => {
                              setIsShowWork(true);
                              e.preventDefault();
                            }}
                          ></div>
                          <div className={Styles.chat_upload}>
                            <FileUpload
                              isMultiple={true}
                              onUploadSuccess={() => {}}
                              getFilesList={getFilesList}
                              isCustom
                              folderPath={"chat"}
                              key={fileUploadKey}
                              maxSize={20}
                            >
                              <Tooltip
                                title={
                                  "支持上传文件(最多20个，每个20MB)支持png、jpg、pdf、doc、docx、xlsx、ppt、txt。"
                                }
                              >
                                <div
                                  className={classnames(
                                    Styles.chat_upload_d,
                                    "iconfont",
                                    "icon-size24",
                                    "icon-a-paperclip_line"
                                  )}
                                  style={{ cursor: "pointer" }}
                                ></div>
                              </Tooltip>
                            </FileUpload>
                          </div>
                          <img
                            className={Styles.chat_insert_send}
                            src={getSendImgSrc()}
                            onClick={(e) => {
                              if (!isTyping) {
                                sendMsg();
                              }
                              e.preventDefault();
                            }}
                            loading="lazy"
                            alt=""
                          ></img>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            height: "36px",
                            gap: "12px",
                          }}
                        >
                          {bigAgentList.map((item: any, index) => {
                            return (
                              <Button
                                key={index}
                                className={
                                  Styles.agentBtn
                                  // item.model_name === currentAgent.model_name
                                  //   ? Styles.agentBtnActive
                                  //   : Styles.agentBtn
                                }
                                onClick={() => {
                                  handleLinkClick();
                                  // setCurrentAgent(item);
                                  // localStorage.setItem(
                                  //   "currentAgent",
                                  //   JSON.stringify(item)
                                  // );
                                }}
                              >
                                <div className={Styles.agentBtnContent}>
                                  <img
                                    src={item.icon}
                                    className={Styles.agentIcon}
                                  />
                                  {item.show_name}
                                  {knowledgeList.length > 0 ? (
                                    <span className={Styles.agentBtnNumbers}>
                                      {knowledgeList.length}
                                    </span>
                                  ) : null}
                                </div>
                              </Button>
                            );
                          })}
                        </div>
                      </div>
                      {isMobile && (
                        <>
                          {chatFiles && chatFiles.length > 0 && (
                            <div className={Styles.filesList}>
                              <UploadFilesList
                                isMobileChat={isMobile}
                                fileList={chatFiles}
                                onDelete={(index: number) => {
                                  setDeleteFileList([
                                    ...deleteFileList,
                                    chatFiles[index],
                                  ]);
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              {chatFiles && chatFiles.length > 0 && !isMobile && (
                <div className={Styles.filesList}>
                  <UploadFilesList
                    fileList={chatFiles}
                    onDelete={(index: number) => {
                      setDeleteFileList([...deleteFileList, chatFiles[index]]);
                    }}
                  />
                </div>
              )}
              <div
                className={Styles.chat_to_bottom}
                style={{
                  visibility: isOnBottom ? "hidden" : "visible",
                  left: "50%",
                }}
              >
                <img
                  className={Styles.chat_insert_send}
                  src={
                    theme === "dark" ? SendDisabledPic : SendLightDisabledPic
                  }
                  onClick={() => {
                    goChatBottom();
                  }}
                  loading="lazy"
                ></img>
              </div>
            </div>
          ) : (
            <div className={Styles.chat_input_box_wrap}>
              {(boxStyle === "chat" || pageType === "addChat") && (
                <Tooltip
                  placement="top"
                  title={"新对话"}
                  color={"#000"}
                  zIndex={10000}
                >
                  <img
                    onClick={() => {
                      addConversation();
                    }}
                    src={onFocus ? conversationActivePig : conversationPig}
                    alt=""
                    className={Styles.chat_input_box_conversation_img}
                  />
                </Tooltip>
              )}
              <div
                className={
                  onFocus
                    ? Styles.chat_input_wrap_active
                    : Styles.chat_input_wrap
                }
                style={
                  ["home_page", "advanced", "chat"].includes(boxStyle!)
                    ? {
                        background: "var(--theme-bg)",
                      }
                    : {}
                }
              >
                <Input.TextArea
                  onFocus={() => {
                    setOnFocus(true);
                  }}
                  onBlur={() => {
                    setOnFocus(false);
                  }}
                  className={Styles.chat_insert_input}
                  value={chatMsg}
                  autoSize={{
                    minRows: boxStyle === "ai_search" ? 2 : 1,
                    maxRows: 5,
                  }}
                  onChange={(e) => {
                    setChatMsg(e.target.value);
                  }}
                  style={
                    ["home_page", "advanced", "chat"].includes(boxStyle!)
                      ? {
                          background: "var(--theme-bg)",
                        }
                      : {}
                  }
                  placeholder={t("chat.input.placeholder")}
                  onPressEnter={(e) => {
                    if (e.which === 13 || e.keyCode === 13) {
                      if (e.shiftKey) {
                        return;
                      }

                      if (!isTyping) {
                        sendMsg();
                      }
                      e.preventDefault();
                    }
                  }}
                  onCompositionStart={() => {
                    setIsTyping(true);
                  }}
                  onCompositionEnd={() => {
                    setIsTyping(false);
                  }}
                ></Input.TextArea>

                <div className={Styles.chat_insert_right}>
                  {btnLoading ? (
                    <div
                      className={Styles.chat_insert_send}
                      style={{
                        background: "rgba(29, 33, 42)",
                        cursor: "pointer",
                        width: "24px",
                        height: "24px",
                        margin: 8,
                        borderRadius: 4,
                      }}
                      onClick={(e) => {
                        onStopChatClick();
                        e.preventDefault();
                      }}
                    >
                      <i
                        className={classnames(
                          "iconfont",
                          "icon-size24",
                          "icon-suspend_full"
                        )}
                        style={{
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      className={Styles.chat_insert_send}
                      src={getSendImgSrc()}
                      onClick={() => {
                        sendMsg();
                      }}
                      loading="lazy"
                    ></img>
                  )}
                </div>
              </div>
            </div>
          )}
          {boxStyle === "ai_search" && (
            <div className={Styles.btnWrap}>
              <div className={Styles.btnContent}>
                <Switch
                  checked={knowledgeBaseSearch}
                  style={{
                    backgroundColor: knowledgeBaseSearch ? "#007890" : "",
                  }}
                  onChange={(checked) => {
                    setKnowledgeBaseSearch(checked);
                  }}
                ></Switch>
                {t("知识库检索")}
              </div>
              <div className={Styles.btnContent}>
                <Switch
                  checked={webSearch}
                  style={{ backgroundColor: webSearch ? "#007890" : "" }}
                  onChange={(checked) => {
                    setWebSearch(checked);
                  }}
                ></Switch>
                {t("联网检索")}
              </div>
            </div>
          )}
        </div>

        {isShowWork ? (
          <Workflow
            isShowWork={isShowWork}
            clearWoke={clearWoke}
            onSend={chatWorkflowSend}
          />
        ) : null}
        <RelateKnowledgeBaseModal
          ref={relateKnowledgeBaseModalRef}
          setCurrentFolderList={(list) => {
            setKnowledgeList(list);
          }}
          curFolderList={knowledgeList}
        ></RelateKnowledgeBaseModal>

        {isShowDynamicForm ? (
          <DynamicForm
            isShowWorkForm={isShowDynamicForm}
            clearDynamicForm={clearDynamicForm}
            userInputForm={userInputForm}
            onSend={chatWorkflowSend}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ChatBox;
