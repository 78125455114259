import Styles from "./index.module.scss";
import {
  toGetAgentList,
  toDeleteAgent,
  toGetCollectAgentList,
  likeStatus,
  toGetUserNames,
  toGetTagData,
} from "@/apis/common";
import { useEffect, useRef, useState } from "react";
import { Pagination, Alert, message, Flex, Radio } from "antd";
import MyAgentItem from "./MyAgentItem";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import qs from "qs";
import CardPic from "@/assets/menu/cardActive.svg";
import classnames from "classnames";
import ConfirmModal from "@/components/Modal";
import CardItem from "@/components/CardItem";
import ToolItem from "@/components/ToolItems";
import { unique } from "@/utils/common";
// import AnnouncePic from "@/assets/enterprise/announce.svg";
// import CustomizationPic from "@/assets/enterprise/customization.svg";

const MyAgent = () => {
  const { t } = useTranslation();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const [cardData, setCardData] = useState([]);
  const [likeData, setLikeData] = useState([]);

  const [data, setData] = useState([]);
  const [deleteAgentName, setDeleteAgentName] = useState("");
  const [deleteAgentId, setDeleteAgentId] = useState(0);
  const [radioType, setRadioType] = useState("cardAssistant");
  const [messageApi, contextHolder] = message.useMessage();
  const [pageType, setPageType] = useState<string>("cardAssistant");

  const [tagNameObj, setTagNameObj] = useState({});
  const [userNameObj, setUserNameObj] = useState({});
  const [likeStatusObj, setLikeStatusObj] = useState({});
  const [needUpdateData, setNeedUpdateData] = useState(false);

  const [category, setCategory] = useState<any>([
    {
      id: "cardAssistant",
      name: t("common.ai.assistant"),
      icon: "icon-tool_fill",
    },
    {
      id: "card",
      name: t("common.ai.knowledge"),
      icon: "icon-a-myknowledge_fill",
    },
  ]);

  const getList = (type: number, curPageNo?: number) => {
    setLoading(true);
    // if (type === 3) {
    toGetCollectAgentList({
      page_number: curPageNo || pageNo,
      page_size: pageSize,
      show_mode: type,
    }).then((res: any) => {
      setLoading(false);
      if (res?.data?.status === 200) {
        if (res.data?.data) {
          const resData = res.data?.data || [];
          type === 1 ? setData(resData) : setCardData(resData);
          setTotal(res.data.total_items);

          likeStatus({
            pub_agent_ids: resData?.map((item: any) => item.id),
          }).then((result: any) => {
            let newLikeStatusObj: any = { ...likeStatusObj };
            if (result.data && result.data.status === 200) {
              result.data.data.forEach((item: any, index: number) => {
                newLikeStatusObj[item.pub_agent_id] = item;
              });
              setLikeStatusObj(newLikeStatusObj);
            }
          });

          if (radioType === "cardAssistant") {
            let tagList: Array<number> = [];
            resData.forEach((item: any, index: number) => {
              if (item.tags && item.tags.length) {
                tagList = [...tagList, ...item.tags];
              }
            });
            if (tagList.length) {
              // 获取 tag 字典
              toGetTagData({ ids: unique(tagList) }).then((res: any) => {
                if (
                  res.data &&
                  res.data.status === 200 &&
                  res.data.data.length
                ) {
                  const tagObj: any = {};
                  res.data.data.forEach((item: any, index: number) => {
                    tagObj[item.id] = item.name;
                  });
                  setTagNameObj(tagObj);
                }
              });
            }
          }

          toGetUserNames({
            ids: unique(resData?.map((item: any) => item.author)),
          }).then((result: any) => {
            if (
              result.data &&
              result.data.status === 200 &&
              result.data.data.length
            ) {
              let obj: any = {};
              result.data.data.forEach((item: any, index: number) => {
                obj[item.id] = item.username;
              });

              setUserNameObj(obj);
            }
          });

          setNeedUpdateData(false);
        }
      }
    });
    return;
    // }
    // toGetAgentList({
    //   page_number: curPageNo || pageNo,
    //   page_size: pageSize,
    //   show_mode: type,
    // }).then((res: any) => {
    //   setLoading(false);
    //   if (res?.data?.status === 200) {
    //     if (res.data?.data) {
    //       const resData = res.data?.data || [];
    //       type === 2 ? setCardData(resData) : setData(resData);
    //       setTotal(res.data.total_items);

    //       if (radioType === "card") {
    //         likeStatus({
    //           pub_agent_ids: resData?.map((item: any) => item.pub_agent_id),
    //         }).then((result: any) => {
    //           let newLikeStatusObj: any = { ...likeStatusObj };
    //           if (result.data && result.data.status === 200) {
    //             result.data.data.forEach((item: any, index: number) => {
    //               newLikeStatusObj[item.pub_agent_id] = item;
    //             });
    //             setLikeStatusObj(newLikeStatusObj);
    //           }
    //         });
    //         setNeedUpdateData(false);
    //       }
    //     }
    //   }
    // });
  };

  const reload = (type: number) => {
    setPageNo(1);
    getList(type);
  };

  const changePagination = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
  };

  const radioTypeChange = (value: string) => {
    setRadioType(value);
    switch (value) {
      case "card":
        setPageNo(1);
        getList(2, 1);
        break;
      case "like":
        setPageNo(1);
        getList(3, 1);
        break;
      default:
        setPageNo(1);
        getList(1, 1);
        break;
    }
  };
  const ConfirmModalRef = useRef<any>(null);
  const onDelete = (agentInfo: any) => {
    // setDeleteAgentName(agentInfo?.name);
    // setDeleteAgentId(agentInfo?.id);
    // ConfirmModalRef?.current?.showModal();
    message.success("取消收藏成功");
    getList(radioType === "card" ? 2 : 1);
  };

  const deleteResult = (success = true) => {
    messageApi.open({
      content: (
        <Alert
          message={t(
            success ? "myagent.delete.success" : "myagent.delete.fail"
          )}
          description={
            success ? (
              <Trans
                i18nKey="myagent.delete.successText"
                values={{ name: deleteAgentName }}
              >
                {deleteAgentName}
              </Trans>
            ) : (
              t("myagent.delete.failText")
            )
          }
          type={success ? "success" : "error"}
          showIcon
          closable
        />
      ),
      className: Styles.customClass,
      duration: 3,
    });
  };

  const confirmFn = () => {
    toDeleteAgent({
      id: deleteAgentId,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        deleteResult();
        ConfirmModalRef?.current?.hideModal();
        getList(radioType === "card" ? 2 : 1);
        return;
      }
      deleteResult(false);
    });
  };

  useEffect(() => {
    getList(radioType === "card" ? 2 : 1);
  }, [pageNo, pageSize]);

  const SkeletonItem = () => {
    return (
      <div className={Styles.skeleton}>
        <Skeleton width="100%" height={200} />
        <div className={Styles.skeleton_info}>
          <div>
            <Skeleton width={30} height={30} borderRadius="50%" />
          </div>
          <div className={Styles.skeleton_info_name}>
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
        </div>
      </div>
    );
  };

  const agentRender = () => {
    let curData =
      radioType === "card"
        ? [...cardData]
        : radioType === "like"
        ? [...likeData]
        : [...data];

    return loading
      ? Array(12)
          .fill(undefined)
          .map((item, index) => {
            return (
              <div key={index} className={Styles.page_recommand_item}>
                <SkeletonItem />
              </div>
            );
          })
      : curData?.map((item, index) => {
          return (
            <div
              key={index}
              className={
                radioType === "card"
                  ? Styles.page_recommand_item
                  : Styles.page_recommand_item_assistant
              }
            >
              {radioType === "card" ? (
                <CardItem
                  agentInfo={item}
                  tagNameObj={tagNameObj}
                  userNameObj={userNameObj}
                  likeStatusObj={likeStatusObj}
                  setLikeStatusObj={setLikeStatusObj}
                  onDelete={onDelete}
                  isSelfItem={false}
                  collectSwitch={true}
                  setNeedUpdateData={setNeedUpdateData}
                  reloadList={reload}
                />
              ) : radioType !== "tool" ? (
                <MyAgentItem
                  agentInfo={item}
                  likeStatusObj={likeStatusObj}
                  userNameObj={userNameObj}
                  onDelete={onDelete}
                />
              ) : (
                <ToolItem
                  agentInfo={item}
                  tagNameObj={tagNameObj}
                  userNameObj={userNameObj}
                  likeStatusObj={likeStatusObj}
                  setLikeStatusObj={setLikeStatusObj}
                  setNeedUpdateData={setNeedUpdateData}
                  collectSwitch={true}
                  reloadList={reload}
                />
              )}
            </div>
          );
        });
  };

  return (
    <div className={classnames(Styles.page)}>
      <div className={Styles.page_header}>
        <div className={classnames(Styles.category_index, "scroll-hide")}>
          {category.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={Styles.category_item}
                onClick={() => {
                  if (!item.disabled) {
                    setPageType(item.id);
                    radioTypeChange(item.id);
                  }
                }}
              >
                <div className={Styles.category_item_name_box}>
                  <div
                    className={classnames(
                      Styles.category_img,
                      pageType === item.id ? Styles.category_img_active : "",
                      "iconfont",
                      "icon-size24",
                      item.icon
                    )}
                  ></div>
                  <div
                    className={classnames(
                      Styles.category_name,
                      pageType === item.id ? Styles.category_name_active : ""
                    )}
                  >
                    {t(item.name)}
                  </div>
                </div>
                <div
                  className={classnames(
                    Styles.category_item_bottom_active,
                    pageType === item.id ? Styles.category_item_bottom : ""
                  )}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={Styles.page_list}>{agentRender()}</div>
      <div className={Styles.pagination}>
        <Pagination
          pageSize={pageSize}
          current={pageNo}
          total={total}
          showSizeChanger={false}
          hideOnSinglePage
          onChange={changePagination}
        />
      </div>
      <ConfirmModal
        ref={ConfirmModalRef}
        confirmFn={confirmFn}
        title={t("myagent.delete.title")}
      >
        <Trans
          i18nKey="myagent.delete.content"
          values={{
            name: deleteAgentName,
          }}
        >
          {deleteAgentName}
        </Trans>
      </ConfirmModal>
      {contextHolder}
    </div>
  );
};

export default MyAgent;
