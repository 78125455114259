import i18n from "@/translation/i18n";

export const modalList = [
  // {
  //   label: "ChatGPT3.5",
  //   modalId: "gpt-3.5-turbo",
  //   value: "gpt-3.5-turbo101",
  //   frameId: 101,
  // },
  {
    label: "Syngents聚合",
    modalId: "gpt-4-1106-preview",
    value: "gpt-4-1106-preview101",
    frameId: 101,
  },
  // {
  //   label: "AutoGen - GPT3.5",
  //   modalId: "gpt-3.5-turbo",
  //   value: "gpt-3.5-turbo201",
  //   frameId: 201,
  // },
  {
    label: "Syngents聚合（支持知识库和工具）",
    modalId: "gpt-4-1106-preview",
    value: "gpt-4-1106-preview201",
    frameId: 201,
  },
];

export const agentTypeList = [
  {
    label: "对话类 Agent",
    value: "chat",
  },
  {
    label: "生成类 Agent",
    value: "generate",
  },
];

export const promptWordList = [
  {
    label: "普通模型工作流",
    value: "normal",
  },
];

export const humanInputModeList = [
  {
    label: "NEVER",
    value: "NEVER",
  },
  // {
  //   label: "ALWAYS",
  //   value: "ALWAYS",
  // },
  // {
  //   label: "TERMINATE",
  //   value: "TERMINATE",
  // },
];

export const skillsAll = [
  {
    title: i18n.t("create.image.create"),
    skill: "generate_images",
    // desc: i18n.t("create.image.desc"),
  },
  // {
  //   title: i18n.t("create.info.search"),
  //   skill: "find_papers_arxiv",
  //   desc: i18n.t("create.image.desc"),
  //   desc: "",
  // },
  // {
  //   title: i18n.t("查询快递"),
  //   skill: "query_courier_route",
  // },
  {
    title: i18n.t("提取网页文本"),
    skill: "explore_website",
  },
  // {
  //   title: i18n.t("使用bing搜索"),
  //   skill: "search_with_bing",
  // },
  // {
  //   title: i18n.t("使用google搜索"),
  //   skill: "search_with_serper",
  // },
  {
    title: i18n.t("使用联网搜索"),
    skill: "search_with_web_search_engine",
  },
  // {
  //   title: i18n.t("在zlibrary查找电子书"),
  //   skill: "find_ebook_from_zlibrary",
  // },
];

export const getSkillsName = (skill: string) => {
  let name = "";
  skillsAll.forEach((item, index) => {
    if (item.skill === skill) {
      // console.log(skill, item.title, 666);
      name = item.title;
    }
  });
  return name;
};
